<template>
  <div
    class="d-flex flex-fill flex-column min-h-100"
  >
    <Logo class="agriplus-logo mt-2 mb-5 animate__animated animate__fadeIn" />

    <div class="flex-fill animate__animated animate__fadeInUp pb-3">
      <h2 class="text-primary">
        {{ $t('pages.farmers.name') }} 🏭
      </h2>
      <template
        v-if="(farmers??[]).length === 0"
      >
        <p class="text-lined pr-1">
          {{ $t('pages.farmers.list.no-farmers') }}
        </p>
        <p class="text-lined pr-1">
          {{ $t('pages.farmers.list.no-farmers-to-fix-contact') }}
        </p>

        <touch-button
          class="mt-2"
          @action="logout"
        >
          {{ $t('therms.logout') }}
        </touch-button>
      </template>
      <template v-else>
        <p class="text-lined pr-1">
          {{ $t('pages.farmers.list.choose-farmer') }}
        </p>

        <div class="mt-3">
          <b-list-group>
            <b-list-group-item
              v-for="farmer in farmers"
              :key="farmer.id"
              class="d-flex justify-content-between align-items-center"
              @action="selectFarmer(farmer.id)"
            >
              {{ farmer.name }}
              <b-list-group-item-button
                disable
                color="primary"
              ><i class="fa-solid fa-angle-right" />
              </b-list-group-item-button>
            </b-list-group-item>
          </b-list-group>
        </div>
      </template>
    </div>

    <Footer class="mt-2" />
  </div>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import ListGroupItem from '@core/components/list-group/ListGroupItem.vue'
import ListGroupItemButton from '@core/components/list-group/ListGroupItemButton.vue'
import { BListGroup } from 'bootstrap-vue'
import Footer from '@core/layouts/components/Footer.vue'

import { mapGetters } from 'vuex'
import { NAME_LOADING } from '@/router/routes/general'
import { Dialog } from '@capacitor/dialog'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import { NAME_INIT } from '@/router/routes/auth'

export default {
  components: {
    Logo,
    BListGroup,
    'b-list-group-item': ListGroupItem,
    'b-list-group-item-button': ListGroupItemButton,
    TouchButton,
    Footer,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      farmers: 'farmers/getList',
    }),
  },
  methods: {
    /**
     * @param {Number} farmerId
     */
    selectFarmer(farmerId) {
      const oldFarmerId = this.$store.getters['farmers/getDefaultFarmer']?.id

      this.$store.commit('farmers/select', farmerId)

      if (this.$store.getters['farmers/getDefaultFarmer']?.id === farmerId) {
        if (oldFarmerId !== farmerId) {
          this.clearStore()
        }

        this.$router.push({ name: NAME_LOADING })
      }
    },
    /**
     * @returns {Promise<void>}
     */
    async logout() {
      const { value } = await Dialog.confirm({
        title: this.$t('therms.logout')
          .toString(),
        message: this.$t('actions.logout.message')
          .toString(),
        okButtonTitle: this.$t('therms.confirm')
          .toString(),
        cancelButtonTitle: this.$t('therms.cancel')
          .toString(),
      })

      if (value && await this.$store.dispatch('auth/logout')) {
        await this.$router.replace({
          name: NAME_INIT,
        })

        this.clearStore(true)
      }
    },
    /**
     * @param {Boolean} farmerAlso Clear farmer list also
     */
    clearStore(farmerAlso = false) {
      if (farmerAlso) this.$store.commit('farmers/clear')

      this.$store.commit('campaigns/clear')
      this.$store.commit('harvestHistory/clear')
      this.$store.commit('harvestNow/clear')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

.agriplus-logo {
  width: 150px;
}
</style>
